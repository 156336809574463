<template>
  <v-dialog
    v-model="dialog"
    :width="$vuetify.breakpoint.mobile ? '95vw' : '1000'"
    persistent
    scrollable
  >
    <template
      v-if="!newApproval"
      #activator="{ on, attrs }"
    >
      <v-btn
        v-bind="attrs"
        outlined
        x-small
        fab
        color="secondary"
        v-on="on"
      >
        <v-icon dark>
          mdi-pencil
        </v-icon>
      </v-btn>
    </template>

    <v-card v-if="model">
      <v-card-title class="headline grey lighten-2">
        Konfiguration af aftale
      </v-card-title>

      <v-card-text>
        <v-form v-model="valid">
          <v-row class="py-4">
            <v-col>
              <h2>
                {{ model.code }} {{ model.typeTitle }}
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      small
                      style="cursor:help"
                      v-bind="attrs"
                      color="grey"
                      v-on="on"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <span>{{ model.typeText }}</span>
                </v-tooltip>
              </h2>
            </v-col>
          </v-row>

          <v-divider />

          <!-- <v-row>
            <v-col>
              <h4><v-icon color="grey lighten-1">mdi-account-check</v-icon> Godkendere</h4>
              <div class="pl-7"> {{model.approverResponsibilitiesByComma}}</div>
            </v-col>
          </v-row> -->

          <!-- <v-divider /> -->

          <v-row class="py-4">
            <v-col>
              <h4>
                <v-icon color="grey lighten-1">
                  mdi-attachment
                </v-icon>Filer <span class="text-body-2">(kun PDF)</span>
              </h4>
              <div class="pl-7">
                <FileList
                  base-url="/approval/file"
                  :files="model.files"
                  deletable
                  no-padding
                />
                <v-file-input
                  v-model="newFiles"
                  :rules="newFileRules"
                  label="Tryk her for at uploade filer"
                  counter
                  multiple
                  show-size
                  truncate-length="25"
                  accept="application/pdf"
                  @change="addFiles"
                />
              </div>
            </v-col>
          </v-row>

          <v-divider />

          <v-row class="py-4">
            <v-col>
              <h4>
                <v-icon color="grey lighten-1">
                  mdi-comment-text-outline
                </v-icon> Aftale tekst
              </h4>
              <div class="pl-7">
                <v-textarea
                  v-model="model.text"
                  outlined
                  label="Information til godkender"
                  counter="2000"
                  :rules="textRules"
                />
              </div>
            </v-col>
          </v-row>

          <template v-if="model.specification">
            <ApprovalSpec
              v-bind="model.specification"
              detailed
            />
          </template>

          <v-divider />

          <v-row class="py-4">
            <v-col>
              <h4>
                <v-icon color="grey lighten-1">
                  mdi-calendar-check
                </v-icon> Deadline
              </h4>
              <div class="pl-7">
                Auto Deadline: {{ model.defaultDeadline | formatDate }}
                <div style="width:250px">
                  <!-- todo validation, advarsel hvis lavere end autoDeadline -->
                  <v-datetime-picker
                    v-model="model.deadline"
                    use-iso
                    without-time
                    label="Godkendelsesdeadline"
                    date-format="yyyy-MM-dd"
                    time-format="HH:mm:ss"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                    </template>
                    <template
                      slot="actions"
                      slot-scope="{ parent }"
                    >
                      <v-btn
                        outlined
                        color="primary"
                        @click.native="parent.cancelHandler"
                      >
                        Annuller
                      </v-btn>
                      <v-btn
                        color="secondary"
                        @click="parent.okHandler"
                      >
                        OK
                      </v-btn>
                    </template>
                  </v-datetime-picker>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-divider />
          <v-row
            v-if="model.referenceInformation"
            class="py-4"
          >
            <v-col>
              <h4>
                <v-icon color="grey lighten-1">
                  mdi-information-outline
                </v-icon> Ekstra info
              </h4>
              <div class="pl-7">
                Godkender besvarer med: {{ model.referenceInformation.label }}
              </div>
            </v-col>
          </v-row>
          <v-divider v-if="model.referenceInformation" />
          <!-- <v-row class="py-4">
            <v-col>
              <v-checkbox v-model="send" label="Lås og afsend til godkendelse" />
            </v-col>
          </v-row> -->
          <v-row class="py-4">
            <v-col>
              <h4>
                <v-icon color="grey lighten-1">
                  mdi-history
                </v-icon> Historik
              </h4>
              <div class="pl-7">
                <small>Oprettet af {{ model.createdByFullNameWithDetails }} @ {{ model.createdOn | formatDateTime }}</small>
                <small v-if="model.sentOn"><br>Sendt af {{ model.sentByFullNameWithDetails }} @ {{ model.sentOn | formatDateTime }}</small>
                <small v-if="model.respondedOn"><br>Behandlet af {{ model.respondedByFullNameWithDetails }} @ {{ model.respondedOn | formatDateTime }}</small>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <template v-for="(input, index) in model.inputs.filter(x => x.trigger === 'Init').sort((a, b) => a.sortOrder - b.sortOrder)">
                <v-text-field
                  v-if="input.inputType === 'Text'"
                  :key="index"
                  v-model="input.value"
                  :label="input.title"
                  :hint="getInputConfig(input, 'hint')"
                  :rules="getRules(input)"
                  outlined
                />

                <v-textarea
                  v-else-if="input.inputType === 'Textarea'"
                  :key="index"
                  v-model="input.value"
                  :label="input.title"
                  :hint="getInputConfig(input, 'hint')"
                  :rules="getRules(input)"
                  outlined
                />

                <DatePick
                  v-else-if="input.inputType === 'Date'"
                  :key="index"
                  :date="input.value"
                  :label="input.title"
                  :rules="getRules(input)"
                  outlined
                  @update="input.value = $event"
                  @clear="input.value = null"
                />

                <v-select
                  v-else-if="input.inputType === 'Dropdown'"
                  :key="index"
                  v-model="input.value"
                  :items="getInputConfig(input, 'options')"
                  :label="input.title"
                  :rules="getRules(input)"
                  outlined
                />
              </template>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          :loading="deleting"
          color="error"
          outlined
          :small="$vuetify.breakpoint.mobile"
          @click="cancel"
        >
          <div class="d-none d-sm-block">
            Slet aftale
          </div>
          <v-icon
            :right="!$vuetify.breakpoint.mobile"
          >
            mdi-delete
          </v-icon>
        </v-btn>

        <v-spacer />

        <v-btn
          outlined
          :small="$vuetify.breakpoint.mobile"
          @click="close"
        >
          {{ $vuetify.breakpoint.mobile ? 'Luk' : 'Annuller' }}
        </v-btn>

        <v-btn
          color="secondary"
          :disabled="!valid"
          :loading="saving"
          :small="$vuetify.breakpoint.mobile"
          @click="save()"
        >
          {{ model.sentOn ? 'Gem' : 'Gem kladde' }}
        </v-btn>

        <v-btn
          color="primary"
          :disabled="!valid"
          :loading="sending"
          :small="$vuetify.breakpoint.mobile"
          @click="save(true)"
        >
          {{ $vuetify.breakpoint.mobile ? 'Afsend' : 'Afsend til godkendelse' }}
          <v-icon right>
            mdi-lock
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileList from '@/components/FileList';
import DatePick from '@/components/DatePick';
import ApprovalSpec from './ApprovalSpec.vue';

export default {
  name: 'ApprovalCreate',
  components: {
    FileList,
    DatePick,
    ApprovalSpec
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    newApproval: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      saving: false,
      sending: false,
      deleting: false,
      deletingFile: false,
      showConfirmDeleteFile: false,
      textMaxLength: 2000,
      newFiles: [],
      newFileRules: [
        (value) => {
          return (
            !value.some((file) => file.type !== 'application/pdf') || 'Du kan kun uploade PDF-filer'
          );
        }
      ],
      textRules: [
        value => (value || '').length <= this.textMaxLength || `Maks. ${this.textMaxLength} tegn`
      ]
    };
  },
  computed: {
    validFiles() {
      if (!this.newFiles.length) {
        return true;
      } else {
        return !this.newFiles.some((file) => file.type !== 'application/pdf');
      }
    },
    refRequired() {
      return !!this.model?.referenceInformation;
    },
    refLabel() {
      return this.model?.referenceInformation?.label;
    }
  },
  methods: {
    close() {
      this.saving = false;
      this.sending = false;
      this.deleting = false;
      this.dialog = false;

      this.$emit('close');
    },
    async save(sendForApproval = false) {
      this.saving = true;

      try {
        const { id, text, deadline, responseComment, inputs, referenceInformation } = this.model;
        const updateData = { id, text, deadline, responseComment, inputs, referenceInformation };

        const response = await this.$AuthService.api.put(`approval/component/initiatorsave`, updateData);

        if (!response.data.success) throw response.data.message;

        this.$emit('update');
        this.close();
      } catch (error) {
        console.error(error);
        this.$notifier.showError({ message: 'Der opstod en fejl da aftalen skulle gemmes. Prøv venligst igen.' })
      } finally {
        this.saving = false;
        this.sending = false;
      }

      if (!sendForApproval) return;

      try {
        this.sending = true;

        const response = await this.$AuthService.api.put(`approval/component/send/${this.model.id}`);

        if (!response.data.success) throw response.data.message;
        
        this.$emit('update')
        this.close();
      } catch (error) {
        console.error(error);
        this.$notifier.showError({ message: 'Øv! Aftalen blev ikke sendt. Prøv venligst igen.' })
      } finally {
        this.sending = false;
      }
    },
    async cancel() {
      if (confirm('Er du sikker på at du vil annullere denne aftale?')) {
        this.deleting = true;

        try {
          await this.$AuthService.api.put(`approval/component/cancel/${this.model.id}`);

          this.$emit('update');
          this.close();
        } catch (error) {
          console.error(error);
          alert(`${this.$t('errors.cancelApproval')} ${this.$t('errors.tryAgain')}`);
        }
      }
    },
    async addFiles() {
      if (this.newFiles.length && !!this.validFiles) {
        const formData = new FormData();

        for (const file of this.newFiles) {
          formData.append('files', file, file.name);
        }

        try {
          const response = await this.$AuthService.api.post(`/approval/${this.model.id}/files`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          this.model.files = this.model.files.concat(response.data.model);
          this.newFiles = [];
        } catch (error) {
          alert(`${this.$t('errors.addFile')} ${this.$t('errors.tryAgain')}`);
          console.error(error);
        }
      }
    },
    getInputConfig(input, key = null) {
      const parsed = JSON.parse(input.configurationJson);
      return key ? parsed?.[key] : parsed;
    },
    /**
     * Generates the rules for each input based on its config
     */
    getRules(input) {
      const config = this.getInputConfig(input);
      let rules = [];

      if (config?.required) {
        rules.push(v => !!v || 'Skal udfyldes');
      }

      if (config?.maxLength) {
        rules.push(v => (v && v.length <= config.maxLength) || `Skal være mindre end ${config.maxLength} tegn`);
      }

      if (config?.minLength) {
        rules.push(v => (v && v.length > config.minLength) || `Skal være mindre end ${config.maxLength} tegn`);
      }

      if (config?.regex) {
        rules.push(v => config.regex.test(v) || 'Den indtastede værdi er ikke gyldig');
      }

      return rules;
    }
  },
  watch: {
    showDialog(value) {
      this.dialog = value;
    }
  }
};
</script>

<style scoped>
tbody tr:hover {
  background-color: transparent !important;
}
</style>