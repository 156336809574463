<template>
  <v-card elevation="2">
    <v-card-title>Aftaler</v-card-title>
    <v-card-text>
      <div v-if="model">
        <v-row v-if="!isClosed">
          <v-col>
            <v-select
              v-model="newTypeId"
              :items="model.metadata.types"
              item-text="text"
              item-value="value"
              label="Vælg en aftaletype"
              dense
              outlined
            />
          </v-col>
          <v-col>
            <v-btn
              :loading="saving"
              :disabled="!newTypeId"
              outlined
              @click="create"
            >
              Tilføj aftale
            </v-btn>
          </v-col>
        </v-row>

        <ApprovalCreate
          :model="newApprovalModel"
          :new-approval="true"
          :show-dialog="showCreateDialog"
          @update="fetchData()"
          @close="showCreateDialog = false"
        />

        <span v-if="model.data.approvals.length === 0"> Der er pt ingen aftaler registreret... </span>

        <div v-else>
          <v-row
            v-for="(approval, i) in model.data.approvals"
            :key="i"
          >
            <v-col>
              <v-card
                :color="$vuetify.theme.dark ? 'secondary darken-3' : 'grey lighten-4'"
                flat
              >
                <v-toolbar
                  color="transparent"
                  flat
                >
                  <v-toolbar-title>
                    {{ approval.code }} {{ approval.typeTitle }}
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <div
                    class="d-flex justify-space-between align-center"
                    style="gap: .5rem"
                  >
                    <ApprovalCreate
                      v-if="!approval.sentOn && approval.userIsInitiator"
                      :model="approval"
                      @update="fetchData(); newApprovalModel = null;"
                    />

                    <ApprovalDetails
                      v-if="approval.respondedOn || (approval.sentOn && !approval.respondedOn && !approval.userIsApprover)"
                      :model="approval"
                    />

                    <ApprovalApprove
                      v-if="approval.sentOn && !approval.respondedOn && approval.userIsApprover"
                      :model="approval"
                      @close="fetchData()"
                      @responded="
                        fetchData();
                        $emit('updated');
                      "
                    />

                    <v-btn
                      color="secondary"
                      outlined
                      x-small
                      fab
                      @click="streamFile(`/approval/${approval.id}/pdf`)"
                    >
                      <v-icon dark>
                        mdi-file-download
                      </v-icon>
                    </v-btn>
                  </div>
                </v-toolbar>
                <v-card-text class="text--primary">
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <div class="font-weight-bold text-caption grey--text text--lighten-1">
                        Afsendelse
                      </div>
                      <div>
                        {{ approval.sentOn ? $options.filters.formatDateTime(approval.sentOn) : 'Ikke afsendt' }}
                      </div>
                      <div class="text-caption">
                        {{ approval.sentByFullNameWithDetails || approval.createdByFullNameWithDetails }}
                      </div>
                    </v-col>
                    <v-col>
                      <div class="font-weight-bold text-caption grey--text text--lighten-1">
                        Deadline
                      </div>
                      <div style="white-space: nowrap;">
                        {{ approval.deadline | formatDate }}
                      </div>
                    </v-col>
                    <v-col class="text-right">
                      <div>
                        <template v-if="!approval.sentOn">
                          <v-chip>
                            Kladde
                            <v-icon
                              class="ml-1"
                              small
                            >
                              mdi-file-question-outline
                            </v-icon>
                          </v-chip>
                        </template>
                        <template v-else-if="approval.respondedOn">
                          <v-chip
                            v-if="approval.approved"
                            color="success"
                          >
                            Godkendt
                            <v-icon
                              class="ml-1"
                              small
                            >
                              mdi-thumb-up-outline
                            </v-icon>
                          </v-chip>
                          <v-chip
                            v-if="!approval.approved"
                            color="error"
                          >
                            Afvist
                            <v-icon
                              class="ml-1"
                              small
                            >
                              mdi-thumb-down-outline
                            </v-icon>
                          </v-chip>
                          <div class="mt-1 text-caption">
                            {{ approval.respondedOn | formatDateTime }}
                          </div>
                        </template>
                        <template v-else>
                          <v-chip
                            v-if="approval.sentOn && !approval.respondedOn"
                            color="warning"
                          >
                            Afventer
                            <v-icon
                              class="ml-1"
                              small
                            >
                              mdi-comment-question-outline
                            </v-icon>
                          </v-chip>
                        </template>
                        <div
                          v-if="approval.referenceInformation"
                          class="reference-information mt-1"
                        >
                          <span>
                            {{ approval.referenceInformation.label }}:
                          </span>
                          <span>
                            {{ approval.referenceInformation.value }}
                          </span>
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <!-- <v-row>
                    <v-col>
                      <div class="mb-1 approval font-weight-bold text-caption grey--text text--lighten-1">
                        <div>
                          Emne
                        </div>

                        <div>
                          Afsendelse
                        </div>

                        <div>
                          Deadline
                        </div>

                        <div>
                          Godkendelse
                        </div>
                      </div>
                      <div class="approval">
                        <div>
                          <div class="font-weight-bold">
                            {{ approval.code }} {{ approval.typeTitle }}
                          </div>
                        </div>
                        <div>
                          <div>
                            {{ approval.sentOn ? $options.filters.formatDateTime(approval.sentOn) : 'Ikke afsendt' }}
                          </div>
                          <div class="text-caption">
                            {{ approval.sentByFullNameWithDetails || approval.createdByFullNameWithDetails }}
                          </div>
                        </div>
                        <div style="white-space: nowrap;">
                          {{ approval.deadline | formatDate }}
                        </div>
                        <div>
                          <template v-if="!approval.sentOn">
                            <v-chip>
                              Kladde
                              <v-icon
                                class="ml-1"
                                small
                              >
                                mdi-file-question-outline
                              </v-icon>
                            </v-chip>
                          </template>
                          <template v-else-if="approval.respondedOn">
                            <v-chip
                              v-if="approval.approved"
                              color="success"
                            >
                              Godkendt
                              <v-icon
                                class="ml-1"
                                small
                              >
                                mdi-thumb-up-outline
                              </v-icon>
                            </v-chip>
                            <v-chip
                              v-if="!approval.approved"
                              color="error"
                            >
                              Afvist
                              <v-icon
                                class="ml-1"
                                small
                              >
                                mdi-thumb-down-outline
                              </v-icon>
                            </v-chip>
                            <div class="mt-1 text-caption">
                              {{ approval.respondedOn | formatDateTime }}
                            </div>
                          </template>
                          <template v-else>
                            <v-chip
                              v-if="approval.sentOn && !approval.respondedOn"
                              color="warning"
                            >
                              Afventer
                              <v-icon
                                class="ml-1"
                                small
                              >
                                mdi-comment-question-outline
                              </v-icon>
                            </v-chip>
                          </template>
                          <div
                            v-if="approval.referenceInformation"
                            class="reference-information"
                          >
                            <span>
                              {{ approval.referenceInformation.label }}:
                            </span>
                            <span>
                              {{ approval.referenceInformation.value }}
                            </span>
                          </div>
                        </div>
                        <div class="text-right">
                          <ApprovalCreate
                            v-if="!approval.sentOn && approval.userIsInitiator"
                            :model="approval"
                            @update="fetchData(); newApprovalModel = null;"
                          />

                          <ApprovalApprove
                            v-if="approval.sentOn && !approval.respondedOn && approval.userIsApprover"
                            :model="approval"
                            @close="fetchData()"
                            @responded="
                              fetchData();
                              $emit('updated');
                            "
                          />

                          <ApprovalDetails
                            v-if="approval.respondedOn || (approval.sentOn && !approval.respondedOn && !approval.userIsApprover)"
                            :model="approval"
                          />
                          <v-btn
                            class="ml-3"
                            color="secondary"
                            outlined
                            x-small
                            fab
                            @click="streamFile(`/approval/${approval.id}/pdf`)"
                          >
                            <v-icon dark>
                              mdi-file-download
                            </v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row> -->

                  <v-divider
                    v-if="approval.textAsHtml || approval.responseComment || approval.files.length || approval.specification"
                    class="my-5"
                  />

                  <v-row v-if="approval.textAsHtml">
                    <v-col>
                      <div>
                        <v-icon left disabled>
                          mdi-message-arrow-right-outline
                        </v-icon>
                        Besked fra aftalestiller
                      </div>
                      <div
                        class="mt-2 text-caption"
                        v-html="approval.textAsHtml"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="approval.responseComment">
                    <v-col>
                      <div>
                        <v-icon left disabled>
                          mdi-message-arrow-left-outline
                        </v-icon>
                        Kommentar til godkendelse
                      </div>
                      <div
                        class="mt-2 text-caption"
                        v-html="approval.responseComment"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="approval.files.length">
                    <v-col>
                      <div>
                        <v-chip
                          v-for="(file, i) in approval.files"
                          :key="i"
                          class="mr-2"
                          label
                          outlined
                          small
                          @click="streamFile(`/approval/file/download/${file.id}`)"
                        >
                          <span>
                            {{ file.fileName }}
                          </span>
                          <v-icon
                            right
                            small
                          >
                            mdi-download
                          </v-icon>
                        </v-chip>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row v-if="approval.details">
                    <v-col>
                      <template v-if="approval.detailsDeserializerClass === 'SpecificationApprovalDetailModel'">
                        <ApprovalSpec v-bind="approval.details" />
                      </template>
                    </v-col>
                  </v-row>

                  <!-- <v-row v-if="approval.specification">
                    <v-col>
                      <ApprovalSpec
                        v-bind="approval.specification"
                      />
                    </v-col>
                  </v-row> -->
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import fileHandler from '@/mixins/fileHandler';
import Loading from '@/components/Loading';
import ApprovalCreate from './ApprovalCreate.vue';
import ApprovalApprove from './ApprovalApprove.vue';
import ApprovalDetails from './ApprovalDetails.vue';
import ApprovalSpec from './ApprovalSpec.vue';

export default {
  name: 'Approval',
  components: {
    Loading,
    ApprovalCreate,
    ApprovalApprove,
    ApprovalDetails,
    ApprovalSpec
  },
  mixins: [fileHandler],
  props: {
    relationKind: {
      type: String,
      required: true
    },
    relationId: {
      type: String,
      required: true
    },
    isClosed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      model: null,
      //View only
      newTypeId: null,
      newApprovalModel: null,
      showCreateDialog: false,
      loading: false,
      saving: false
    };
  },
  watch: {
    relationId() {
      this.fetchData();
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;

      try {
        const response = await this.$AuthService.api.get(`approval/component`, {
          params: {
            relationKind: this.relationKind,
            relationId: this.relationId
          }
        });

        this.model = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async create() {
      this.saving = true;

      try {
        await this.$AuthService.api.post(`approval/component/create/${this.relationId}/${this.newTypeId}`);
        await this.fetchData();

        // Open a dialog with the newly created approval
        const approval = this.model.data.approvals[0];

        if (!approval.id) {
          throw new Error('Could not open the newly created approval: Missing information.');
        }

        this.newApprovalModel = approval;
        this.newTypeId = null;
        this.showCreateDialog = true;
        this.$emit('create:success');
      } catch (error) {
        this.$store.dispatch('logError', { level: 'critical', transaction: 'ApprovalCreate', error })
        this.$notifier.showError({ message: this.$t('errors.createApproval') });
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.reference-information > span:first-child {
  font-weight: bold;
  margin-right: 0.5rem;
}

.approval {
  display: grid;
  grid-template-columns: 30% minmax(250px, 1fr) minmax(90px, 20%) minmax(110px, 1fr) 130px;
  text-align: left;
  align-items: flex-start;
}
</style>