<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    persistent
    scrollable
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        :title="$t('supportTickets.dialogFollowers.title')"
        small
        v-on="on"
        @click="getData"
      >
        <v-icon>mdi-account-plus-outline</v-icon>
        <v-icon>mdi-account-remove-outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="title white--text secondary">
        {{ $t('deliveries.dialogFollowers.title') }}
      </v-card-title>
      <v-card-text>
        <Loading :loading="!loaded" />
        <v-container
          v-if="loaded && model"
          fluid
        >
          <v-row>
            <v-col>
              <h2>{{ $t('deliveries.dialogFollowers.persons.title') }}</h2>
              <vMultiselectListbox 
                v-model="selectedPersons"
                :options="model.metadata.persons"
                :reduce-display-property="(option) => option.text"
                :reduce-value-property="(option) => option.value"
                :search-options-placeholder="$t('supportTickets.dialogFollowers.persons.searchOptions')"
                :selected-options-placeholder="$t('supportTickets.dialogFollowers.persons.searchSelected')"
                :no-options-text="$t('supportTickets.dialogFollowers.persons.noOption')"
                :selected-no-options-text="$t('supportTickets.dialogFollowers.persons.noSelected')"
                :no-options-found-text="$t('supportTickets.dialogFollowers.persons.noOptionFound')"
                :no-selected-options-found-text="$t('supportTickets.dialogFollowers.persons.noSelectedFound')"
                @change="hasChanged = true"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions v-if="loaded">
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          @click="cancel"
        >
          Annuller
        </v-btn>
        <v-btn
          color="secondary"
          :loading="saving"
          :disabled="!hasChanged"
          @click="save"
        >
          Gem
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from '@/components/Loading';

export default {
  name: 'DeliveryResponsibleDialog',
  components: {
    Loading
  },
  data() {
    return {
      model: null,
      dialog: false,
      loaded: false,
      saving: false,
      hasChanged: false,
      selectedPersons: []
    };
  },
  methods: {
    async getData() {
      const vm = this;
      const url = `delivery/changefollowers/${vm.$route.params.id}`;

      try {
        const response = await vm.$AuthService.api.get(url);

        vm.model = response.data;
        vm.selectedPersons = vm.model.metadata.persons.filter(el => vm.model.data.followerPersonIds.includes(el.value));
        vm.loaded = true;
      } catch (error) {
        console.error(error);
      }
    },
    cancel() {
      this.dialog = false;
    },
    async save() {
      const vm = this;
      vm.saving = true;

      const data = {
        followerPersonIds: vm.selectedPersons.map(el => el.value)
      }

      try {
        await vm.$AuthService.api.put(`delivery/changefollowers/${vm.$route.params.id}`, data);
      } catch (error) {
        console.error(error);
      } finally {
        vm.$emit('update:followers');
        this.saving = false;
        this.dialog = false;
      }
    }
  }
};
</script>