<template>
  <div>
    <span v-if="!model || model.length === 0">{{ $t('deliveries.activities.list.noActivities') }}</span>
    <div v-else>
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th>{{ $t('deliveries.activities.list.headers.startdate') }}</th>
              <th>{{ $t('deliveries.activities.list.headers.endDate') }}</th>
              <th style="width: 40%">
                {{ $t('deliveries.activities.list.headers.title') }}
              </th>
              <th>{{ $t('deliveries.activities.list.headers.duration') }}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <DeliveryActivityListItem
              v-for="(activity, i) in orderedActivities"
              :key="i"
              :activity="activity"
              :activities="orderedActivities"
              :metadata="model.metadata"
              @saved="reload"
            />
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import DeliveryActivityListItem from '@/components/delivery/DeliveryActivityListItem.vue'

export default {
    name: 'DeliveryActivityList',
    components: { DeliveryActivityListItem },
    props: {
        deliveryId: String
    },
    data() {
        return {
            model: null
        };
    },

    computed: {
        orderedActivities() {
            return [...this.model.data.activities].sort((a, b) => a.sortOrder - b.sortOrder);
        }
    },
    watch: {
        deliveryId() {
            this.reload();
        }
    },

    created() {
        this.reload();
    },

    methods: {
        orderBySortOrder(items) {
            return [...items].sort((a, b) => a.sortOrder - b.sortOrder);
        },

        // async activitySortOrderChanged(activity) {

        // },

        async reload() {
            const vm = this;
            const response = await vm.$AuthService.api.get(`delivery/${this.deliveryId}/activities`);
            vm.model = response.data;
            vm.loaded = true;
        },
    }
};
</script>
