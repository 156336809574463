<template>
  <div>
    <v-card :loading="loading">
      <v-card-title flat>
        Specifikationer

        <v-spacer></v-spacer>

        <v-menu
          v-model="createMenu"
          :close-on-content-click="false"
          min-width="500"
          transition="slide-y-transition"
        >
          <template #activator="{ attrs, on }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :loading="creating"
              icon
              color="primary"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-text>
              <v-form
                ref="newSpecForm"
                v-model="valid"
                @submit.prevent
                lazy-validation
              >
                <v-text-field
                  v-model="newSpecTitle"
                  label="Titel"
                  :rules="[ v => !!v || 'Udfyld en titel' ]"
                  autofocus
                  @keydown.enter="create"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn @click="create">
                Opret
                <v-icon right> mdi-creation </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-card-title>

      <v-card-text>
        <!-- <v-card
          v-for="spec in specs"
          :key="spec.id"
          class=""
        >
          <v-card-text>
            {{ spec.title }} ({{ spec.sections.length }} sektioner)
          </v-card-text>
        </v-card> -->

        <v-list>
          <v-list-item
            v-for="spec in specs"
            :key="spec.id"
            :to="`specification/${spec.id}`"
            append
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ spec.title }}
              </v-list-item-title>

              <v-list-item-subtitle>
                {{ spec.groups.length }} grupper
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-icon v-if="!!spec.lockedOn">
              <div class="d-flex align-center">
                <div class="mr-1 text-caption grey--text"> {{ spec.lockedOn | formatDate }} </div>
                <v-icon> mdi-lock-outline </v-icon>
              </div>
            </v-list-item-icon>
          </v-list-item>

          <div
            v-if="!specs.length"
            class="text-caption secondary--text"
          >
            Ingen specifikationer tilføjet
          </div>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'SpecificationComponent',
  data() {
    return {
      createMenu: false,
      creating: false,
      loading: false,
      newSpecTitle: '',
      specs: [],
      valid: false
    }
  },
  methods: {
    async fetchData() {
      const { id } = this.$route.params;

      try {
        this.loading = true;

        const response = await this.$AuthService.api.get(`specification/relation/${id}`);

        if (!response.data.success) throw response.data.message ?? 'Error getting specifications';

        this.specs = response.data.model;
        this.$emit('loaded', this.specs.map(spec => spec.id));
      } catch (error) {
        this.$notifier.showError({});
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async create() {
      if (!this.$refs.newSpecForm.validate()) {
        return;
      }

      const spec = {
        title: this.newSpecTitle,
        relationId: this.$route.params.id,
        relationType: 'CustomerDelivery'
      };

      try {
        this.creating = true;
        
        const response = await this.$AuthService.api.post('specification', spec);

        // this.$router.push(`specification/${response.data.model}`);
        this.fetchData();
      } catch (error) {
        console.error(error);
        this.$notifier.showError({});
      } finally {
        this.createMenu = false;
        this.creating = false;
      }
    },
  },
  mounted() {
    this.fetchData();
  }
}
</script>
