<template>
  <v-dialog
    v-model="dialog"
    :width="$vuetify.breakpoint.mobile ? '95vw' : '900'"
    persistent
    scrollable
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        outlined
        x-small
        fab
        color="secondary"
        v-on="on"
      >
        <v-icon dark>
          mdi-information-variant
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline grey lighten-2">
        Detaljer
      </v-card-title>

      <v-card-text>
        <v-row class="py-4">
          <v-col>
            <h2>
              {{ model.code }} {{ model.typeTitle }}
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                    small
                    style="cursor:help"
                    v-bind="attrs"
                    color="grey"
                    v-on="on"
                  >
                    mdi-information
                  </v-icon>
                </template>
                <span>{{ model.typeText }}</span>
              </v-tooltip>
            </h2>
          </v-col>
        </v-row>

        <v-divider />

        <v-row class="py-4">
          <v-col>
            <h4>
              <v-icon color="grey lighten-1">
                mdi-attachment
              </v-icon> Filer
            </h4>
            <div class="pl-7">
              <FileList
                base-url="/approval/file"
                :files="model.files"
                no-padding
              />
            </div>
          </v-col>
        </v-row>

        <v-divider />

        <v-row class="py-4">
          <v-col>
            <h4>
              <v-icon disabled>
                mdi-message-arrow-right-outline
              </v-icon> Besked fra aftalestiller
            </h4>
            <div
              class="pl-7"
              v-html="model.textAsHtml"
            />
          </v-col>
        </v-row>

        <template v-if="model.specification">
          <ApprovalSpec
            v-bind="model.specification"
            detailed
          />
        </template>

        <v-divider />

        <!-- <v-row>
          <v-col>
            <h4><v-icon color="grey lighten-1">mdi-account-check</v-icon> Godkendere</h4>
            <div class="pl-7"> {{model.approverResponsibilitiesByComma}}</div>
          </v-col>
        </v-row> -->

        <!-- <v-divider /> -->

        <v-row
          v-if="model.referenceInformation"
          class="py-4"
        >
          <v-col>
            <h4>
              <v-icon disabled>
                mdi-information-outline
              </v-icon> {{ model.referenceInformation.label }}
            </h4>
            <div class="pl-7">
              {{ model.referenceInformation.value }}
            </div>
          </v-col>
        </v-row>

        <v-divider v-if="model.referenceInformation" />

        <v-row class="py-4">
          <v-col>
            <h4>
              <v-icon disabled>
                mdi-message-arrow-left-outline
              </v-icon> Kommentar til godkendelse
            </h4>
            <div class="pl-7">
              {{ model.responseComment }}
            </div>
          </v-col>
        </v-row>

        <v-divider />

        <v-row>
          <v-col>
            <template v-for="(input, index) in model.inputs.filter(x => x.trigger === 'Init').sort((a, b) => a.sortOrder - b.sortOrder)">
              <v-text-field
                v-if="input.inputType === 'Text'"
                :key="index"
                v-model="input.value"
                :label="input.title"
                disabled
                outlined
                dense
              />

              <v-textarea
                v-else-if="input.inputType === 'Textarea'"
                :key="index"
                v-model="input.value"
                :label="input.title"
                disabled
                outlined
                dense
              />

              <v-text-field
                v-else-if="input.inputType === 'Date'"
                :key="index"
                v-model="input.value"
                :label="input.title"
                prepend-icon="mdi-calendar"
                disabled
                outlined
                dense
              />

              <v-select
                v-else-if="input.inputType === 'Dropdown'"
                :key="index"
                v-model="input.value"
                :items="getInputConfig(input, 'options')"
                :label="input.title"
                disabled
                outlined
                dense
              />
            </template>
          </v-col>
        </v-row>

        <v-divider />

        <v-row class="py-4">
          <v-col>
            <h4>
              <v-icon color="grey lighten-1">
                mdi-history
              </v-icon> Historik
            </h4>
            <div class="pl-7">
              <small>Oprettet af {{ model.createdByFullNameWithDetails }} @ {{ model.createdOn | formatDateTime }}</small>
              <small v-if="model.sentOn"><br>Sendt af {{ model.sentByFullNameWithDetails }} @ {{ model.sentOn | formatDateTime }}</small>
              <small v-if="model.respondedOn"><br>Behandlet af {{ model.respondedByFullNameWithDetails }} @ {{ model.respondedOn | formatDateTime }}</small>
            </div>
          </v-col>
        </v-row>

        <v-divider />

        <v-row
          v-if="model.respondedOn"
          class="py-4"
        >
          <v-col>
            <h4>
              <v-icon color="grey lighten-1">
                mdi-checkbox-marked-circle-outline
              </v-icon> Resultat
            </h4>
            <div class="pl-7">
              Aftalen er
              <span
                v-if="model.approved"
                class="success--text font-weight-bold"
              >
                <v-icon
                  dark
                  color="success"
                >mdi-comment-check</v-icon>Godkendt
              </span>
              <span
                v-if="!model.approved"
                class="error--text font-weight-bold"
              >
                <v-icon
                  dark
                  color="error"
                >mdi-comment-remove-outline</v-icon>Afvist
              </span>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="primary"
          outlined
          @click="dialog = false"
        >
          Luk
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileList from '@/components/FileList.vue';
import ApprovalSpec from './ApprovalSpec.vue';

export default {
  name: 'ApprovalDetails',
  components: {
    FileList,
    ApprovalSpec
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    getInputConfig(input, key = null) {
      const parsed = JSON.parse(input.configurationJson);
      return key ? parsed?.[key] : parsed;
    },
    /**
     * Generates the rules for each input based on its config
     */
    getRules(input) {
      const config = this.getInputConfig(input);
      let rules = [];

      if (config?.required) {
        rules.push(v => !!v || 'Skal udfyldes');
      }

      if (config?.maxLength) {
        rules.push(v => (v && v.length <= config.maxLength) || `Skal være mindre end ${config.maxLength} tegn`);
      }

      if (config?.minLength) {
        rules.push(v => (v && v.length > config.minLength) || `Skal være mindre end ${config.maxLength} tegn`);
      }

      if (config?.regex) {
        rules.push(v => config.regex.test(v) || 'Den indtastede værdi er ikke gyldig');
      }

      return rules;
    }
  }
}
</script>

<style>
.download-icon {
  position: absolute !important;
  left: 1.7rem;
  height: 1.3rem;
}
</style>