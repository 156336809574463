<template>
  <div>
    <v-row v-if="detailed">
      <v-col>
        <v-card
          :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
        >
          <v-card-title>
            Varer

            <v-spacer></v-spacer>

            Pris i alt: DKK {{ totalPrice | formatNumberFixed2 }}
          </v-card-title>
          <v-card-subtitle>
            {{ title }}
          </v-card-subtitle>
          <v-card-text>
            <v-data-table
              :headers="headers"
              group-by="groupName"
              show-group-by
              :items="sections"
              :search="search"
            >
              <template #[`item.price`]="{ item }">
                {{ getSectionPrice(item) | formatNumberFixed2 }}
              </template>

              <template #[`item.partsCount`]="{ item }">
                {{ getSectionPartCount(item) }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        cols="12"
        md="6"
      >
        <div class="text-subtitle-1 font-weight-bold">
          {{ title }}
        </div>
        <div class="mt-3">
          Varegrupper i tilbud
        </div>
        <ul>
          <li
            v-for="group in partGroups"
            :key="group"
          >
            {{ group }}
          </li>
        </ul>
      </v-col>
      <v-col class="text-right">
        <div class="text-subtitle-2">
          Totalpris
        </div>
        <div class="font-weight-bold">
          DKK {{ totalPrice | formatNumberFixed2 }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ApprovalSpec',
  props: {
    detailed: {
      type: Boolean,
      default: false
    },
    groups: {
      type: Array,
      default: []
    },
    title: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      headers: [
        { text: 'Sektionsnavn', value: 'title', groupable: false },
        { text: 'Gruppe', value: 'groupName' },
        { text: 'Vareantal', value: 'partsCount', groupable: false },
        { text: 'Antal (sektion)', value: 'quantity', groupable: false },
        { text: 'Pris', value: 'price', align: 'end', groupable: false }
      ],
      search: ''
    }
  },
  computed: {
    partGroups () {
      const mapped = this.parts?.map(part => part.group);

      return Array.from(new Set(mapped));
    },
    sections() {
      return this.groups.filter(group => !!group.sections.length).flatMap(group => group.sections);
    },
    parts () {
      return this.sections?.flatMap(section => section.parts);
    },
    totalPrice () {
      return this.parts?.reduce((acc, currentValue) => acc + (currentValue.price * currentValue.quantity), 0);
    }
  },
  methods: {
    getSectionPrice(section) {
      const partsPrice = section.parts.reduce((acc, current) => acc + (current.price * current.quantity), 0);

      return partsPrice * section.quantity;
    },
    getSectionPartCount(section) {
      return section.parts.reduce((acc, current) => acc + current.quantity, 0);
    }
  }
}
</script>
