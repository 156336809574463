<template>
  <v-dialog
    v-model="dialog"
    width="800"
    persistent
    scrollable
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        color="secondary"
        v-on="on"
      >
        Svar
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline grey lighten-2">
        Aftale godkendelse
      </v-card-title>

      <v-card-text>
        <v-row class="py-4">
          <v-col>
            <h2>
              {{ model.code }} {{ model.typeTitle }}
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                    small
                    style="cursor:help"
                    v-bind="attrs"
                    color="grey"
                    v-on="on"
                  >
                    mdi-information
                  </v-icon>
                </template>
                <span>{{ model.typeText }}</span>
              </v-tooltip>
            </h2>
          </v-col>
        </v-row>

        <v-divider />

        <v-row class="py-4">
          <v-col>
            <h4>
              <v-icon color="grey lighten-1">
                mdi-attachment
              </v-icon> Filer
            </h4>
            <div class="pl-7">
              <FileList
                base-url="/approval/file"
                :files="model.files"
                no-padding
              />
            </div>
          </v-col>
        </v-row>

        <v-divider />

        <v-form v-model="valid">
          <v-row class="py-4">
            <v-col>
              <h4>
                <v-icon color="grey lighten-1">
                  mdi-comment-text-outline
                </v-icon> Information til godkender
              </h4>
              <div
                class="pl-7"
                v-html="model.textAsHtml"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <template v-for="(input, index) in model.inputs.filter(x => x.trigger === 'Send')">
                <v-text-field
                  v-if="input.inputType === 'Text'"
                  :key="index"
                  v-model="input.value"
                  :label="input.title"
                  :hint="getInputConfig(input, 'hint')"
                  :rules="getRules(input)"
                  outlined
                />
                <v-textarea
                  v-else-if="input.inputType === 'Textarea'"
                  :key="index"
                  v-model="input.value"
                  :label="input.title"
                  :hint="getInputConfig(input, 'hint')"
                  :rules="getRules(input)"
                  outlined
                />
                <DatePick
                  v-else-if="input.inputType === 'Date'"
                  :key="index"
                  :date="input.value"
                  :label="input.title"
                  :rules="getRules(input)"
                  outlined
                  @update="input.value = $event"
                  @clear="input.value = null"
                />
                <v-select
                  v-else-if="input.inputType === 'Dropdown'"
                  :key="index"
                  v-model="input.value"
                  :items="getInputConfig(input, 'options')"
                  :label="input.title"
                  :rules="getRules(input)"
                  outlined
                />
              </template>
            </v-col>
          </v-row>
          <v-divider />
          <!-- <v-row class="py-4">
            <v-col>
              <h4><v-icon color="grey lighten-1">mdi-account-check</v-icon> Godkendere</h4>
              <div class="pl-7"> {{model.approverResponsibilitiesByComma}}</div>
            </v-col>
          </v-row> -->
          <!-- <v-divider /> -->
          <v-row class="py-4">
            <v-col cols="12">
              <h4>
                <v-icon color="grey lighten-1">
                  mdi-checkbox-marked-circle-outline
                </v-icon> Behandling
              </h4>
            </v-col>
            <v-col cols="4">
              <v-radio-group
                v-model="approvalValue"
                class="pl-7 text-left"
              >
                <v-radio
                  label="Ikke angivet"
                  value="notset"
                />
                <v-radio
                  value="decline"
                  color="error"
                >
                  <template #label>
                    <div><span class="error--text">Afvis</span></div>
                  </template>
                </v-radio>
                <v-radio
                  value="approve"
                  color="success"
                >
                  <template #label>
                    <div><span class="success--text">Godkend</span></div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col>
              <v-text-field
                v-if="model.referenceInformation"
                v-model="model.referenceInformation.value"
                :label="model.referenceInformation.label"
                maxlength="250"
              />
              <v-textarea
                v-model="model.responseComment"
                outlined
                label="Kommentar til godkendelse"
              />
              <v-alert
                text
                type="info"
                class="text-caption"
              >
                OBS! Kommentaren kan ikke bruges til at ændre vilkårene i den aftale der godkendes.
              </v-alert>
            </v-col>
          </v-row>
        </v-form>

        <v-divider />

        <v-row class="py-4">
          <v-col>
            <h4>
              <v-icon color="grey lighten-1">
                mdi-history
              </v-icon> Historik
            </h4>
            <div class="pl-7">
              <small>Oprettet af {{ model.createdByFullNameWithDetails }} @ {{ model.createdOn | formatDateTime }}</small>
              <small v-if="model.sentOn"><br>Sendt af {{ model.sentByFullNameWithDetails }} @ {{ model.sentOn | formatDateTime }}</small>
              <small v-if="model.respondedOn"><br>Behandlet af {{ model.respondedByFullNameWithDetails }} @ {{ model.respondedOn | formatDateTime }}</small>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="close"
        >
          Annuller
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!valid"
          :loading="saving"
          @click="save"
        >
          Gem
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileList from '@/components/FileList';

export default {
  name: 'ApprovalApprove',
  components: {
    FileList
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      approvalValue: 'notset',
      dialog: false,
      valid: false,
      loading: false,
      saving: false,
      deleting: false,
      newFiles: [],
      newFileRules: [
        (value) => {
          return (
            !value.some((file) => file.type !== 'application/pdf') || 'Du kan kun uploade PDF-filer'
          );
        }
      ]
    };
  },
  computed: {
    validFiles() {
      if (!this.newFiles.length) {
        return true;
      } else {
        return !this.newFiles.some((file) => file.type !== 'application/pdf');
      }
    },
    refRequired() {
      return !!this.model.referenceInformation;
    },
    refLabel() {
      return this.model.referenceInformation?.label;
    }
  },
  methods: {
    close() {
      this.approvalValue = 'notset';
      this.saving = false;
      this.deleting = false;
      this.dialog = false;
    },
    async save() {
      this.saving = true;

      try {
        const response = await this.$AuthService.api.put(`approval/component/approversave`, this.model);

        if (!response.data?.success) {
          throw new ReferenceError('Approval was not successful');
        }

        // If approvalValue is different than 'notset', the user has responded as well
        if (this.approvalValue !== 'notset') {
          await this.$AuthService.api.put(`approval/component/respond/${this.model.id}/${this.approvalValue}`);

          this.$emit('responded');
        }

        else {
          this.$emit('close');
        }

        this.close();
      } catch (error) {
        console.error(error);
        alert(error.message);
      } finally {
        this.saving = false;
      }
    },
    getInputConfig(input, key = null) {
      const parsed = JSON.parse(input.configurationJson);
      return key ? parsed?.[key] : parsed;
    },
    /**
     * Generates the rules for each input based on its config
     */
    getRules(input) {
      const config = this.getInputConfig(input);
      let rules = [];

      if (config?.required) {
        rules.push(v => !!v || 'Skal udfyldes');
      }

      if (config?.maxLength) {
        rules.push(v => (v && v.length <= config.maxLength) || `Skal være mindre end ${config.maxLength} tegn`);
      }

      if (config?.minLength) {
        rules.push(v => (v && v.length > config.minLength) || `Skal være mindre end ${config.maxLength} tegn`);
      }

      if (config?.regex) {
        rules.push(v => config.regex.test(v) || 'Den indtastede værdi er ikke gyldig');
      }

      return rules;
    }
  }
};
</script>