<template>
  <v-dialog
    v-model="dialog"
    scrollable
  >
    <template #activator="{ attrs, on }">
      <v-list-item
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-icon>
          <v-icon>mdi-pencil</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Rediger leverance</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-card>
      <v-card-title class="title white--text secondary">
        Ændring af leverancedetaljer
      </v-card-title>

      <Loading :loading="loading" />

      <v-card-text>
        <v-container
          v-if="model"
          fluid
        >
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="model.data.deliveryType"
                :items="model.metadata.deliveryTypes"
                item-text="text"
                item-value="value"
                label="Type"
                return-object
                :search-input.sync="deliveryInput"
                auto-select-first
                small-chips
                outlined
dense @input="deliveryInput = null"
              />
              <v-autocomplete
                v-model="model.data.location"
                :items="model.metadata.locations"
                item-text="text"
                item-value="value"
                label="Lokation"
                return-object
                :search-input.sync="locationInput"
                auto-select-first
                small-chips
                outlined
dense @input="locationInput = null"
              />
              <v-autocomplete
                v-model="model.data.departments"
                :items="model.metadata.departments"
                item-text="text"
                item-value="value"
                label="Afdelinger"
                return-object
                :search-input.sync="departmentInput"
                auto-select-first
                small-chips
                outlined
dense multiple @input="departmentInput = null"
              />
              <v-autocomplete
                v-model="model.data.subjectAreas"
                :items="model.metadata.subjectAreas"
                item-text="text"
                item-value="value"
                label="Tekniske områder"
                return-object
                :search-input.sync="subjectInput"
                auto-select-first
                small-chips
                outlined
dense multiple @input="subjectInput = null"
              />
              <v-autocomplete
                v-model="model.data.status"
                :items="model.metadata.statuses"
                item-text="text"
                item-value="value"
                label="Status"
                return-object
                :search-input.sync="statusInput"
                auto-select-first
                small-chips
                outlined
dense @input="statusInput = null"
              />
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="model.data.followerPersons"
                :items="model.metadata.persons"
                item-text="text"
                item-value="value"
                label="Følgere"
                return-object
                :search-input.sync="followerPersonsInput"
                auto-select-first
                small-chips
                outlined
dense multiple @input="followerPersonsInput = null"
              />
              <v-textarea
                v-model="model.data.waitingForAction"
                :label="$t('deliveryEdit.waitingForAction')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              <!--
              <DatePick label="Forv start" clearable="false" :date="model.data.expectedStart" />
              <DatePick label="Forv slut" clearable="false" :date="model.data.expectedEnd" />
              <DatePick label="Start" clearable="true" :date="model.data.startedOn" />
              <DatePick label="Slut" clearable="true" :date="model.data.endedOn" />
              -->
              <v-menu
                v-for="input in dateInputs.slice(0, 4)"
                :key="input.name"
                v-model="input.menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="model.data[input.name]"
                    :label="input.label"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="model.data[input.name]"
                  @input="input.menu = false"
                />
              </v-menu>
            </v-col>

            <v-col
              cols="12"
              sm="4"
            >
              <v-menu
                v-for="input in dateInputs.slice(4, 8)"
                :key="input.name"
                v-model="input.menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="model.data[input.name]"
                    :label="input.label"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="model.data[input.name]"
                  @input="input.menu = false"
                />
              </v-menu>
            </v-col>

            <v-col
              cols="12"
              sm="4"
            >
              <v-menu
                v-for="input in dateInputs.slice(8)"
                :key="input.name"
                v-model="input.menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="model.data[input.name]"
                    :label="input.label"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="model.data[input.name]"
                  @input="input.menu = false"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="model.data.title"
                label="Titel"
              />
              <v-currency-field
                v-model="model.data.expectedConsultants"
                label="Forv. antal konsulenter"
              />
              <v-currency-field
                v-model="model.data.expectedDays"
                label="Forv. teknikerdage"
              />
              <v-currency-field
                v-model="model.data.expectedPrice"
                label="Forv. DKK"
              />
            </v-col>
            <v-col>
              <v-textarea
                v-model="model.data.description"
                label="Beskrivelse..."
              />
              <v-textarea
                v-model="model.data.focusPoints"
                label="Opmærksomhedspunkter..."
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          @click="cancel"
        >
          Annuller
        </v-btn>
        <v-btn
          color="secondary"
          :loading="saving"
          @click="save"
        >
          Gem
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from '@/components/Loading.vue';

export default {
  name: 'DeliveryEditDialog',
  components: {
    Loading
  },
  data() {
    return {
      dialog: false,
      loading: false,
      saving: false,
      model: null,
      deliveryInput: null,
      locationInput: null,
      departmentInput: null,
      subjectInput: null,
      statusInput: null,
      followerPersonsInput: null,
      dateInputs: [
        {
          name: 'expectedStart',
          label: 'Forv. start',
          menu: false
        },
        {
          name: 'expectedEnd',
          label: 'Forv. slut',
          menu: false
        },
        {
          name: 'startedOn',
          label: 'Faktisk start',
          menu: false
        },
        {
          name: 'endedOn',
          label: 'Faktisk slut',
          menu: false
        },
        {
          name: 'expectedDeliveryDescriptionApprovedOn',
          label: 'Forv. lev. godk.',
          menu: false
        },
        {
          name: 'expectedPartsReadyForShipmentOn',
          label: 'Forv. mat. klar i DK',
          menu: false
        },
        {
          name: 'expectedWorkStartedOn',
          label: 'Forv. udførsel start',
          menu: false
        },
        {
          name: 'expectedWorkEndedOn',
          label: 'Forv. udførsel slut',
          menu: false
        },
        {
          name: 'deliveryDescriptionApprovedOn',
          label: 'Lev. godk.',
          menu: false
        },
        {
          name: 'partsReadyForShipmentOn',
          label: 'Mat. klar i DK',
          menu: false
        },
        {
          name: 'workStartedOn',
          label: 'Udførsel start',
          menu: false
        },
        {
          name: 'workEndedOn',
          label: 'Udførsel slut',
          menu: false
        },
        {
          name: 'closedOn',
          label: 'Leverance lukket',
          menu: false
        },
      ]
    };
  },
  watch: {
    dialog(value) {
      if (value) {
        this.fetchData();
      }
    }
  },
  methods: {
    fetchData: async function () {
      this.loading = true;

      try {
        const response = await this.$AuthService.api.get(`delivery/edit/${this.$route.params.id}`);
        this.model = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    save: async function () {
      this.saving = true;

      try {
        await this.$AuthService.api.put(`delivery/edit/${this.$route.params.id}`, this.model.data);

        this.dialog = false;
        this.$emit('saved');
      } catch (error) {
        console.error(error);
      } finally {
        this.saving = false;
      }
    },
    cancel: function () {
      this.dialog = false;
    }
  }
};
</script>