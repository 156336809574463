<template>
  <tr>
    <td class="nowrap">
      <span v-if="activity.startDate">{{ activity.startDate | formatDate }}</span>
    </td>
    <td class="nowrap">
      <span
        v-if="activity.endDate"
        :class="activity.isReferenceFixed ? 'font-weight-bold' : 'font-italic'"
      >{{ activity.endDate | formatDate }}</span>
    </td>
    <td><span>{{ activity.title }}</span></td>
    <td>
      <span>{{ $tc(`deliveries.activities.timeUnits.${activity.timeUnit}`, activity.timeAmount) }} </span>
      <span v-if="activity.referenceType === 'SpecificDate'">{{ $t('deliveries.activities.referenceTypes.specificDate') }} {{ activity.referenceId | formatDate }}</span>
      <span v-if="activity.referenceType === 'PreviousActivity'">{{ $t('deliveries.activities.referenceTypes.previousActivity') }}</span>
      <span v-if="activity.referenceType === 'FirstApprovalOfType'">{{ $t('deliveries.activities.referenceTypes.firstApprovalOfType', { approvalType: getApprovalTypeName(activity.referenceId) }) }}</span>
      <span v-if="activity.referenceType === 'LatestApprovalOfType'">{{ $t('deliveries.activities.referenceTypes.latestApprovalOfType', { approvalType: getApprovalTypeName(activity.referenceId) }) }}</span>
      <span v-if="activity.referenceType === 'SpecificApproval'">{{ $t('deliveries.activities.referenceTypes.specificApproval', { approvalCode: getApprovalCode(activity.referenceId) }) }}</span>
      <span v-if="activity.referenceType === 'DeliveryProperty'">{{ $t(`deliveries.activities.referenceTypes.deliveryProperty.${activity.referenceId}`) }}</span>
    </td>
    <td
      v-if="user.organizationKind == 'Internal'"
      class="nowrap"
    >
      <v-btn
        icon
        small
        color="secondary"
        @click="editActivity()"
      >
        <v-icon
          dark
          small
        >
          mdi-pencil
        </v-icon>
      </v-btn>
      <v-btn
        icon
        small
        color="secondary"
        class="ml-1"
        :class="{'btn-invisible' : !canMoveUp}"
        @click.stop="changeSortOrder(-1)"
      >
        <v-icon
          dark
          small
        >
          mdi-arrow-up
        </v-icon>
      </v-btn>
      <v-btn
        icon
        small
        color="secondary"
        class="ml-1"
        :class="{'btn-invisible' : !canMoveDown}"
        @click="changeSortOrder(1)"
      >
        <v-icon
          dark
          small
        >
          mdi-arrow-down
        </v-icon>
      </v-btn>
      <DeliveryActivityUpsertDialog
        v-if="showDialog"
        :activity="activity"
        @saved="updateSaved"
        @cancelled="updateCancelled()"
      />
    </td>
    <td
      v-if="user.organizationKind != 'Internal'"
      colspan="2"
    />
  </tr>
</template>

<script>
import DeliveryActivityUpsertDialog from '@/components/delivery/DeliveryActivityUpsertDialog.vue'

export default {
    name: 'DeliveryActivityListItem',
    components: { DeliveryActivityUpsertDialog },
    props: {
        activity: Object,
        activities: Array,
        metadata: Object
    },

    data() {
        return {
            showDialog: false
        }
    },

    computed: {
        user() {
            return this.$store.getters.currentUser;
        },

        startDate() {
            const previousActivity = this.getPreviousActivity();
            if (previousActivity) {
                return previousActivity.deadline;
            }
            return null;
        },

        canMoveUp() {
            return !!this.getPreviousActivity();
        },

        canMoveDown() {
            return !!this.getFollowingActivity();
        }
    },

    methods: {
        getApprovalTypeName(approvalTypeId) {
            return this.metadata.approvalTypes.find(x => x.value === approvalTypeId)?.text || '';
        },

        getApprovalCode(approvalId) {
            return this.metadata.approvals.find(x => x.value === approvalId)?.text || '';
        },

        editActivity() {
            this.showDialog = true;
        },

        updateCancelled() {
            this.showDialog = false;
        },

        updateSaved() {
            this.showDialog = false;
            this.$emit('saved');
        },

        async changeSortOrder(direction) {
            const otherActivity = direction > 0 ? this.getFollowingActivity() : this.getPreviousActivity();
            if (otherActivity) {
                await this.$AuthService.api.put(`delivery/${this.$route.params.id}/activities/${this.activity.id}/sortorder`, { sortOrder: otherActivity.sortOrder });
                this.$emit('saved');
            }
        },

        getPreviousActivity() {
            return this.activities
                .filter(x => x.sortOrder < this.activity.sortOrder)
                .sort((a, b) => b.sortOrder - a.sortOrder)[0];
        },

        getFollowingActivity() {
            return this.activities
                .filter(x => x.sortOrder > this.activity.sortOrder)
                .sort((a, b) => a.sortOrder - b.sortOrder)[0];
        }
    }
};
</script>

<style scoped>
.btn-invisible {
    visibility: hidden;
}
td.nowrap {
    white-space: nowrap;
}
</style>
