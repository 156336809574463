<template>
  <v-dialog
    v-model="showDialog"
    width="500"
    persistent
    scrollable
  >
    <template #activator="{ on, attrs }">
      <span v-if="showCreateButton">
        <v-btn
          icon
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </span>
    </template>
    <v-card v-if="loaded">
      <v-card-title class="title white--text secondary">
        {{ isCreate ? $t('deliveries.activities.upsert.titleCreate') : $t('deliveries.activities.upsert.titleUpdate') }}
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-combobox
                v-model="model.title"
                :items="standardTitles"
                item-text="text"
                item-value="value"
                :label="$t('deliveries.activities.upsert.titleLabel')"
                outlined
                dense
              />
              <v-text-field
                v-model.number="model.timeAmount"
                type="number"
                :label="$t('deliveries.activities.upsert.timeAmountLabel')"
                outlined
                dense
              />
              <v-combobox
                v-model="model.timeUnit"
                :items="timeUnits"
                item-text="text"
                item-value="value"
                :label="$t('deliveries.activities.upsert.timeUnitsLabel')"
                outlined
                dense
              />
              <v-combobox
                v-model="model.referenceType"
                :items="referenceTypes"
                item-text="text"
                item-value="value"
                :label="$t('deliveries.activities.upsert.referenceTypeLabel')"
                outlined
                dense
                @change="referenceTypeChanged()"
              />
              <!-- Reference id selectors -->
              <v-datetime-picker
                v-if="model.referenceType.value === 'SpecificDate'"
                v-model="selectedSpecificDate"
                :label="$t('deliveries.activities.upsert.referenceId.specificDateLabel')"
                use-iso
                without-time
                date-format="yyyy-MM-dd"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
                <template
                  slot="actions"
                  slot-scope="{ parent }"
                >
                  <v-btn
                    outlined
                    color="primary"
                    @click.native="parent.cancelHandler"
                  >
                    Annuller
                  </v-btn>
                  <v-btn
                    color="secondary"
                    @click="parent.okHandler"
                  >
                    OK
                  </v-btn>
                </template>
              </v-datetime-picker>
              <v-combobox
                v-if="model.referenceType.value === 'FirstApprovalOfType' || model.referenceType.value === 'LatestApprovalOfType'"
                v-model="model.referenceId"
                :items="metadata.approvalTypes"
                item-text="text"
                item-value="value"
                :label="$t('deliveries.activities.upsert.referenceId.approvalType')"
                outlined
                dense
              />
              <v-combobox
                v-if="model.referenceType.value === 'SpecificApproval'"
                v-model="model.referenceId"
                :items="metadata.approvals"
                item-text="text"
                item-value="value"
                :label="$t('deliveries.activities.upsert.referenceId.approval')"
                outlined
                dense
              />
              <v-combobox
                v-if="model.referenceType.value === 'DeliveryProperty'"
                v-model="model.referenceId"
                :items="deliveryProperties"
                item-text="text"
                item-value="value"
                :label="$t('deliveries.activities.upsert.referenceId.deliveryProperty')"
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          v-if="!isCreate"
          outlined
          @click="remove()"
        >
          {{ $t('deliveries.activities.upsert.delete') }}
          <v-icon right>
            mdi-delete
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          @click="cancel()"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          color="secondary"
          :disabled="!model.title || !canSave"
          @click="save()"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    name: 'DeliveryActivityUpsertDialog',
    props: {
        activity: Object,
        showCreateButton: {
            default: false,
            type: Boolean
        }
    },

    data() {
        return {
            loaded: false,
            isCreate: false,
            metadata: Object,
            model: null,
            showDialog: !this.showCreateButton,
            standardTitles: [
                { text: this.$t('deliveries.activities.upsert.standardTitles.deliveryCreate'), value: 'deliveryCreate' },
                { text: this.$t('deliveries.activities.upsert.standardTitles.taskDescriptionUpload'), value: 'taskDescriptionUpload' },
                { text: this.$t('deliveries.activities.upsert.standardTitles.deliveryDescriptionUpload'), value: 'deliveryDescriptionUpload' },
                { text: this.$t('deliveries.activities.upsert.standardTitles.deliveryDescriptionApproval'), value: 'deliveryDescriptionApproval' },
                { text: this.$t('deliveries.activities.upsert.standardTitles.materialsReadyInDK'), value: 'materialsReadyInDK' },
                { text: this.$t('deliveries.activities.upsert.standardTitles.executionStart'), value: 'executionStart' },
                { text: this.$t('deliveries.activities.upsert.standardTitles.executionEnd'), value: 'executionEnd' },
                { text: this.$t('deliveries.activities.upsert.standardTitles.deliveryApproval'), value: 'deliveryApproval' }
            ],
            selectedSpecificDate: null
        }
    },

    computed: {
        timeUnits() { 
            return this.metadata.timeUnits.map(x => ({ value: x.value, text: this.$t(`deliveries.activities.upsert.timeUnits.${x.text}`) })) 
        },
        referenceTypes() {
            return this.metadata.referenceTypes.map(x => ({ value: x.value, text: this.$t(`deliveries.activities.upsert.referenceTypes.${x.text}`) }))
        },
        deliveryProperties() {
            return this.metadata.deliveryProperties.map(x => ({ value: x.value, text: this.$t(`deliveries.activities.upsert.deliveryProperties.${x.text}`) }))
        },
        canSave() {
            if (this.model?.referenceType?.value === 'SpecificDate' && !this.model.referenceId) {
                return false;
            } else {
                return true;
            }
        }

    },
    watch: {
        selectedSpecificDate(value) {
            /**
             * This is not a great way to handle this, but doing more would require an overhaul og how dates are stored application-wide.
             * To get around an issue coming from timezones, the hour is manually set to 12:00 to avoid the date changing when stored.
             */
            this.model.referenceId = this.moment(value).hour(12).toISOString();
        }
    },

    async created() {
        this.init();
    },

    methods: {
        referenceTypeChanged() {
            switch (this.model.referenceType.value) {
                case 'SpecificDate':
                    if (isNaN(this.model.referenceId)) {
                        const date = new Date();
                        this.model.referenceId = new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString();
                    }
                    break;
                case 'PreviousActivity':
                    this.model.referenceId = null;
                    break;
                case 'FirstApprovalOfType':
                case 'LatestApprovalOfType': {
                    const referenceId = (this.model.referenceId && this.model.referenceId.value) ? this.model.referenceId.value : this.model.referenceId;
                    this.model.referenceId = this.metadata.approvalTypes.find(x => x.value === referenceId);
                    if (!this.model.referenceId) {
                        this.model.referenceId = this.metadata.approvalTypes[0];
                    }
                    break;
                }
                case 'SpecificApproval': {
                    const referenceId = (this.model.referenceId && this.model.referenceId.value) ? this.model.referenceId.value : this.model.referenceId;
                    this.model.referenceId = this.metadata.approvals.find(x => x.value === referenceId);
                    if (!this.model.referenceId) {
                        this.model.referenceId = this.metadata.approvals[0];
                    }
                    break;
                }
                case 'DeliveryProperty': {
                    const referenceId = (this.model.referenceId && this.model.referenceId.value) ? this.model.referenceId.value : this.model.referenceId;
                    this.model.referenceId = this.deliveryProperties.find(x => x.value === referenceId);
                    if (!this.model.referenceId) {
                        this.model.referenceId = this.deliveryProperties[0];
                    }
                    break;
                }
                default:
                    throw new Error(`Reference type ${this.model.referenceType.value} is not implemented`);
            }
        },

        cancel() {
            this.showDialog = false;
            this.$emit('cancelled');
        },

        async remove() {
            await this.$AuthService.api.delete(`delivery/${this.$route.params.id}/activities/${this.model.id}`);
            this.showDialog = false;
            this.$emit('saved');
        },

        async save() {
            setTimeout(async () =>  {
                let referenceId = this.model.referenceId;
                if (referenceId && referenceId.value) {
                    referenceId = referenceId.value;
                }

                const modelTitle = this.model.title.text ? this.model.title.text : this.model.title;

                let response;
                if (this.isCreate) {
                    response = await this.$AuthService.api
                        .post(`delivery/${this.$route.params.id}/activities`, {
                            title: modelTitle,
                            timeUnit: this.model.timeUnit.value,
                            timeAmount: this.model.timeAmount,
                            referenceType: this.model.referenceType.value,
                            referenceId: referenceId
                        });
                } else {
                    response = await this.$AuthService.api
                        .put(`delivery/${this.$route.params.id}/activities/${this.model.id}`, {
                            title: modelTitle,
                            timeUnit: this.model.timeUnit.value,
                            timeAmount: this.model.timeAmount,
                            referenceType: this.model.referenceType.value,
                            referenceId: referenceId
                        });
                }
                if (response.data.success) {
                    this.showDialog = false;
                    this.init();
                    this.$emit('saved');
                }
            }, 1);
        },

        async init() {
            const vm = this;
            const response = await vm.$AuthService.api.get(`delivery/${this.$route.params.id}/activities/edit`);
            vm.metadata = response.data;
            vm.loaded = true;

            this.isCreate = !this.activity;
            if (this.isCreate) {
                this.model = {
                    timeUnit: this.timeUnits[0],
                    timeAmount: 1,
                    referenceType: this.referenceTypes.find(x => x.value === 'PreviousActivity'),
                    referenceId: null
                };
            } else {
                this.model = {
                    ...this.activity,
                    timeUnit: this.timeUnits.find(x => x.value === this.activity.timeUnit),
                    referenceType: this.referenceTypes.find(x => x.value === this.activity.referenceType)
                };
            }
        }
    }
};
</script>
