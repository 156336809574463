<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    persistent
    scrollable
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        small
        @click="getData"
      >
        <v-icon>
          mdi-account-switch-outline
        </v-icon>
      </v-btn>
    </template>

    <v-card :loading="!loaded">
      <v-card-title class="title white--text secondary">
        Ændring af {{ titleLower }}
      </v-card-title>
      <v-card-text>
        <v-container
          v-if="loaded && model"
          fluid
        >
          <v-row>
            <v-col>
              <div class="font-weight-bold">
                Aktuelt tildelt
              </div>
              <div>
                {{ currentResponsibleName }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="model.data.newPersonId"
                :items="model.metadata.responsibles"
                item-text="text"
                item-value="value"
                :label="`Ny ${titleLower}`"
                outlined
                dense
                @change="hasChanged = true"
              />
            </v-col>
          </v-row>
          <!-- Kommentarer til leverance er skjult indtil senere -->
          <!-- <v-row>
            <v-col>
              <v-textarea
                v-model="model.data.comment"
                label="Kommentar til leverance"
                @change="hasChanged = true"
                outlined
              />
            </v-col>
          </v-row> -->
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions v-if="loaded">
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          @click="cancel"
        >
          Annuller
        </v-btn>
        <v-btn
          color="secondary"
          :loading="saving"
          :disabled="!hasChanged || !responsibleIsDifferent"
          @click="save"
        >
          Gem
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from '@/components/Loading'

export default {
  name: 'DeliveryResponsibleDialog',
  components: {
    Loading
  },
  props: {
    title: {
      type: String,
      required: true
    },
    responsibilityId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      model: null,
      dialog: false,
      loaded: false,
      saving: false,
      hasChanged: false
    }
  },
  computed: {
    currentResponsibleName() {
      if (!this.model.data.personId) {
        return 'Ikke fundet';
      } else {
        return this.model?.metadata?.responsibles?.find(el => el.value === this.model?.data?.personId).text;
      }
    },
    titleLower() {
      return this.title.toLowerCase();
    },
    responsibleIsDifferent() {
      if (!this.model.data.newPersonId) {
        return false;
      } else {
        return this.model.data.personId !== this.model.data.newPersonId;
      }
    }
  },
  methods: {
    async getData() {
      const vm = this;
      const url = `delivery/${vm.$route.params.id}/ResponsibleEdit/${vm.responsibilityId}`;

      try {
        const result = await vm.$AuthService.api.get(url);

        vm.model = result.data;
        vm.loaded = true;
      } catch (error) {
        console.error(error);
      }
    },
    cancel() {
      this.model.data.newPersonId = null;
      this.dialog = false;
    },
    async save() {
      const vm = this;
      vm.saving = true;

      try {
        await vm.$AuthService.api.put('delivery/ResponsibleEdit', vm.model.data);
        vm.$emit('update:responsible');
      } catch (error) {
        console.error(error);
      } finally {
        this.saving = false;
        this.dialog = false;
      }
    }
  }
}
</script>