<template>
  <v-container fluid>
    <Loading :loading="!loaded" text="Henter leverance" />

    <div v-if="loaded">
      <v-row>
        <v-col>
          <DeliveryCard
            :item="model.data"
            :metadata="model.metadata"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!-- ---- card Leverancedetaljer ---- -->
          <v-card elevation="2">
            <v-toolbar flat>
              <v-toolbar-title>
                {{ $t('deliveryDetails.detailsTitle') }}
              </v-toolbar-title>

              <v-spacer />

              <v-menu
                v-if="user.organizationKind == 'Internal'"
                v-model="detailMenu"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    fab
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-subheader>Leverance</v-subheader>
                  <v-list-item-group color="primary">
                    <DeliveryEditDialog
                      v-if="user.organizationKind == 'Internal'"
                      :model="model"
                      @saved="init"
                    />
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="3">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <th>{{ $t('deliveryDetails.locationId') }}</th>
                        <td><LocationLink :id="model.data.locationId" /></td>
                      </tr>
                      <tr>
                        <th>{{ $t('deliveryDetails.code') }}</th>
                        <td>{{ model.data.code }}</td>
                      </tr>
                      <tr v-if="model.data.sourceTaskRequestId">
                        <th>{{ $t('deliveryDetails.sourceTaskRequestId') }}</th>
                        <td>
                          <router-link :to="`/taskrequest/${model.data.sourceTaskRequestId}`">
                            {{ model.data.sourceTaskRequestCodeAndTitle }}
                          </router-link>
                        </td>
                      </tr>    
                      <tr>
                        <th>{{ $t('deliveryDetails.referenceInformations', { customerName: model.data.customerInitials }) }}</th>
                        <td>{{ model.data.referenceInformations.map(x => x.value).join(', ') }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
                <v-col cols="5">
                  <v-simple-table>
                    <tbody>
                      <tr
                        v-for="(r,i) in model.data.responsibles"
                        :key="i"
                      >
                        <th class="d-flex align-center">
                          <v-icon left>mdi-account-outline</v-icon>
                          {{ r.responsibilityTitle }}
                        </th>
                        <td>
                          <span
                            v-if="r.personId"
                            class="text-caption"
                          >{{ r.personFullName }} ({{ r.personOrganizationInitials }})</span>
                        </td>
                        <td class="text-right">
                          <DeliveryResponsibleDialog
                            v-if="!!r.userCanEdit"
                            :title="r.responsibilityTitle"
                            :responsibility-id="r.responsibilityId"
                            @update:responsible="init"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <v-icon left>mdi-account-multiple-outline</v-icon>
                          {{ $t('deliveryDetails.followers') }}
                        </th>
                        <td class="text-caption">
                          {{ model.data.followerPersons.length }} følgere
                        </td>
                        <td class="text-right">
                          <DeliveryFollowersDialog @update:followers="init" />
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
                <v-col>
                  <v-simple-table>
                    <tbody>
                      <tr v-if="model.data.waitingForAction">
                        <th><v-icon>mdi-timer-sand</v-icon>{{ $t('deliveryDetails.waitingForAction') }}</th>
                        <td>{{ model.data.waitingForAction }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('deliveryDetails.deliveryDescriptionApprovedOn') }}</th>
                        <td>
                          <ValueState
                            :actual="model.data.deliveryDescriptionApprovedOn"
                            :expected="model.data.expectedDeliveryDescriptionApprovedOn"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>{{ $t('deliveryDetails.partsReadyForShipmentOn') }}</th>
                        <td>
                          <ValueState
                            :actual="model.data.partsReadyForShipmentOn"
                            :expected="model.data.expectedPartsReadyForShipmentOn"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>{{ $t('deliveryDetails.workStartedOn') }}</th>
                        <td>
                          <ValueState
                            :actual="model.data.workStartedOn"
                            :expected="model.data.expectedWorkStartedOn"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>{{ $t('deliveryDetails.workEndedOn') }}</th>
                        <td>
                          <ValueState
                            :actual="model.data.workEndedOn"
                            :expected="model.data.expectedWorkEndedOn"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="7"
          xl="8"
        >
          <v-row>
            <v-col>
              <Approval
                :is-closed="isClosed"
                :relation-kind="'delivery'"
                :relation-id="model.data.id"
                @create:success="init()"
              />
            </v-col>
          </v-row>

          <v-row v-if="user.organizationKind == 'Internal'">
            <v-col>
              <SpecificationComponent
                @loaded="specIds = $event"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <!-- ---- leverance aktiviteter ---- -->
              <v-card elevation="2">
                <v-card-title>
                  {{ $t('deliveries.activities.title') }}
                  <div class="flex-grow-1" />
                  <DeliveryActivityUpsertDialog
                    v-if="user.organizationKind == 'Internal'"
                    :show-create-button="true"
                    @saved="activitySaved()"
                  />
                </v-card-title>
                <v-card-text>
                  <DeliveryActivityList
                    ref="activities"
                    :delivery-id="model.data.id"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <!-- ---- card Leveranceoplysninger ---- -->
              <v-card elevation="2">
                <v-card-title>
                  {{ $t('deliveryDetails.informationTitle') }}
                </v-card-title>
                <v-card-text>
                  <span v-html="model.data.descriptionHtml" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col>
          <ActivityLog
            title="Leveranceforløb"
            :add-endpoint="`delivery/${$route.params.id}/entry`"
            :relationId="$route.params.id"
            relationKind="Delivery"
            :entry-template="model.data.entryTemplate"
            :entries="model.data.entries"
            :closed="!!model.data.closedOn"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import ActivityLog from '@/components/ActivityLog.vue';
import DeliveryCard from '@/components/delivery/DeliveryCard'
import Approval from '@/components/approval/Approval.vue'
import DeliveryActivityList from '@/components/delivery/DeliveryActivityList.vue'
import DeliveryActivityUpsertDialog from '@/components/delivery/DeliveryActivityUpsertDialog.vue'
import ValueState from '@/components/ValueState.vue';
import LocationLink from '@/components/LocationLink.vue';
import Loading from '@/components/Loading.vue';
import DeliveryResponsibleDialog from '@/components/delivery/DeliveryResponsibleDialog'
import DeliveryFollowersDialog from '@/components/delivery/DeliveryFollowersDialog'
import DeliveryEditDialog from '@/components/delivery/DeliveryEditDialog'
import SpecificationComponent from '@/components/specification/SpecificationComponent'

export default {  
  name: 'DeliveryDetail',
  components: {
    ActivityLog,
    DeliveryCard,
    ValueState,
    Approval,
    DeliveryActivityList,
    LocationLink,
    DeliveryActivityUpsertDialog,
    Loading,
    DeliveryResponsibleDialog,
    DeliveryFollowersDialog,
    DeliveryEditDialog,
    SpecificationComponent
  },
  beforeRouteLeave(to, from, next) {
    if (this.model.data.entryTemplate.text !== '') {
      const answer = window.confirm('Vil du navigere væk? Din besked vil ikke blive gemt.');

      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  data() {
    return {
      model: Object,
      detailMenu: false,
      specIds: [],
      //view only
      loaded: false,
      t: this.$t
    }
  },
  computed: {
    user() {
      return this.$store.getters.currentUser;
    },
    isClosed() {
      return !!this.model.data?.closedOn;
    }
  },
  watch: {
    '$route.params.id': {
      handler: function() {
        this.init();
        
      }
    }
  },
  created() {
    var vm = this;
    vm.init();
  },
  methods: {
    async init() {
      this.detailMenu = false;

      try {
        const response = await this.$AuthService.api.get(`delivery/detail/${this.$route.params.id}`);

        this.model = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loaded = true;
      }
    },
    activitySaved: function() {
      this.$refs.activities.reload();
    }
  }
}
</script>